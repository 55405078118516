<div class="flex flex-col px-[7.15rem] pt-[3.2rem] pb-[4.2rem] min-w-max min-h-max" *ngIf="!isLoading else loadingTemplate">
  <!-- <div class="search-container">
    <i class="icon-search"></i>

    <input type="text" placeholder="Pesquisar">
  </div> -->

  <div class="card mt-5">
    <div class="flex flex-row py-[2rem] items-center gap-[0.8rem]">
      <h2>Contas</h2>
      <span class="quantity-company !font-bold !text-[1.4rem] !cursor-default">Total: {{ count }}</span>
      <span class="quantity-company" [class.!text-white]="filteredRole == 'CUSTOMER'" [class.!bg-prairie-sand]="filteredRole == 'CUSTOMER'" (click)="filterByRole('CUSTOMER')">Cliente</span>
      <span class="quantity-company" [class.!text-white]="filteredRole == 'ARCHITECT'" [class.!bg-prairie-sand]="filteredRole == 'ARCHITECT'" (click)="filterByRole('ARCHITECT')">Arquiteto</span>
      <span class="quantity-company" [class.!text-white]="filteredRole == 'COMPANY'" [class.!bg-prairie-sand]="filteredRole == 'COMPANY'" (click)="filterByRole('COMPANY')">Fornecedor</span>
      <span class="quantity-company" [class.!text-white]="filteredRole == 'STAFF'" [class.!bg-prairie-sand]="filteredRole == 'STAFF'" (click)="filterByRole('STAFF')">Admin</span>

      <span class="text-prairie-sand cursor-pointer hover:font-bold" (click)="filterByRole('')">Limpar filtro</span>
    </div>

    <table>
      <tr>
        <th class="whitespace-nowrap w-[1/7]">Nome</th>
        <th class="w-[1/7] whitespace-nowrap">E-mail</th>
        <th class="w-[1/7]">Telefone</th>
        <th class="w-[1/7]">Tipo da conta</th>
        <th class="w-[1/7]">Data de Cadastro</th>
        <th class="w-[1/7]">Editar</th>
      </tr>

      <tr *ngFor="let account of accounts">
        <td>{{ account.name ?? '-' }} <ng-container *ngIf="account.role == 'COMPANY'">({{ getDisplayActive(account.company?.is_active ?? false) }})</ng-container></td>
        <td>{{ account.email ?? '-' }}</td>
        <td>{{ account.phone_number ?? ''}}</td>
        <td>{{ getDisplayRole(account.role) }}</td>
        <td>{{ account.created_at ?? '' | date: 'dd/MM/yyyy'}}</td>
        <td><i *ngIf="account.role == 'COMPANY'" class="icon-edit cursor-pointer" (click)="goToCompanyProfile(account.company?.id)"></i></td>
      </tr>
    </table>

    <div class="card-footer">
      <button (click)="getPage(currentPage - 1)" [disabled]="currentPage == 1">
        <i class="icon-arrow-left"></i>
        Anterior
      </button>

      <div class="pagination">
        <span class="cursor-pointer" [class.font-bold]="currentPage == i + 1" (click)="getPage(i + 1)" *ngFor="let _ of [].constructor(pageCount); let i = index">{{ i + 1 }}</span>
      </div>
      <button (click)="getPage(currentPage + 1)" [disabled]="currentPage == pageCount">
        Próxima
        <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
